import store from "@/store/index";
import { formatDate } from "@/utils/common";
import DatePicker from "@/components/DatePicker";
import InfiniteLoading from "vue-infinite-loading";
import {
  pluginDataIdDownloadGet,
  getUserKeys,
  createUserKey,
} from "@/clients/tracelight";
import FileSaver from "file-saver";
import moment from "moment";

export default {
  name: "ScrapingResult",
  components: { DatePicker, InfiniteLoading },
  data: () => ({
    initialLoad: true,
    clearStart: false,
    clearEnd: false,
    initialStartDate: null,
    initialEndDate: null,
    moment: moment,
    showDownloadImagesTooltip: false,
    showCopyTooltip: false,
    userApiKey: "",
    selectedItem: null,
    showClipboard: false,
    clipboardMsg: null,
    searchInput: "",
    acceptSearchInput: true,
    debounceSearchInputTimeout: 200,
    fab: false,
    selectedUser: "All",
    infiniteId: 1,
    headers: [
      { text: "Date", value: "created_at" },
      { text: "Comments", value: "user_comment" },
      { text: "URL", value: "url" },
      { text: "", value: "icons" },
    ],
    formatDate: formatDate,
  }),
  created() {
    store.dispatch("scrapingResult/getUsers");
  },
  mounted() {
    this.$refs.datePickerStartDate.dateData = moment()
      .subtract(365, "days")
      .format("YYYY-MM-DD");
    this.$refs.datePickerEndDate.dateData = moment().format("YYYY-MM-DD");
    let queryString = window.location.search;
    let urlParams = new URLSearchParams(queryString);
    this.updateUrlParams(urlParams);
  },
  methods: {
    updateUrlParams(urlParams) {
      for (const [key, value] of urlParams.entries()) {
        const val = urlParams.get(key);
        if (key == "scraped_by" && val !== "null") {
          this.selectedUser = val;
        }
        if (key == "created_before" && val !== "null") {
          this.$refs.datePickerEndDate.dateData =
            moment(val).format("YYYY-MM-DD");
        }
        if (key == "created_after" && val !== "null") {
          this.$refs.datePickerStartDate.dateData =
            moment(val).format("YYYY-MM-DD");
        }
        if (key == "q" && val !== "null") {
          this.searchInput = val;
        }
      }
    },
    copyText(text, selector = null) {
      console.warn(text);
      let parentElem = document.body;
      if (selector) {
        parentElem = document.querySelector(selector);
      }
      console.warn(parentElem);
      const el = document.createElement("textarea");
      el.value = text;

      parentElem.appendChild(el);
      el.select();
      document.execCommand("copy");
      parentElem.removeChild(el);
      this.clipboardMsg = "Copied!";
      this.showClipboard = true;
    },
    copyApiKey() {
      getUserKeys({})
        .then((result) => {
          if (result.data.length < 1) {
            createUserKey({}, {})
              .then((result) => {
                alert(result.data.msg);
                this.userApiKey = result.data.key.value;
                this.copyText(this.userApiKey, ".v-dialog");
              })
              .catch(function (error) {
                console.error(error);
              });
          } else {
            this.userApiKey = result.data[0].value;
            this.copyText(this.userApiKey, ".v-dialog");
          }
        })
        .catch(function (error) {
          console.error(error);
        });
    },
    copyPermalink(id) {
      this.copyText(window.location.origin + `/scrapingresultdetails/${id}`);
    },
    scrapingDetails(id) {
      window.location.href =
        window.location.origin + `/scrapingresultdetails/${id}`;
    },
    selectItem(item) {
      this.selectedItem = item;
    },
    unSelectItem() {
      this.showDownloadImagesTooltip = false;
      this.showPluginTooltip = false;
      this.selectedItem = null;
    },
    getResults: function () {
      this.infiniteId += 1;
      store.dispatch("scrapingResult/getResults");
    },
    updateDateRangeStart: function (dates) {
      if (dates[0]) {
        this.clearStart = false;
      }
      store.commit("scrapingResult/replaceValue", {
        key: "page",
        value: 1,
      });
      store.commit("scrapingResult/replaceValue", {
        key: "created_after",
        value: dates[0],
      });
      if (this.initialLoad == false) {
        this.getResults();
      }
    },
    updateDateRangeEnd: function (dates) {
      if (dates[0]) {
        this.clearEnd = false;
      }
      store.commit("scrapingResult/replaceValue", {
        key: "page",
        value: 1,
      });
      store.commit("scrapingResult/replaceValue", {
        key: "created_before",
        value: dates[0],
      });

      this.getResults();
      this.initialLoad = false;
    },
    onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.fab = top > 100;
    },
    toTop: function () {
      this.$vuetify.goTo(0);
    },
    infiniteHandler($state) {
      store.dispatch("scrapingResult/loadMoreResults").then((result) => {
        if (result === null) {
          $state.loaded();
          return;
        }
        if (result.length > 0) {
          $state.loaded();
          return;
        }
        if (result.length === 0) {
          $state.complete();
          return;
        }
      });
    },
  },
  computed: {
    results() {
      const results = store.state.scrapingResult.results || [];
      for (let r of results) {
        r.icons = null;
      }
      return results;
    },
    users() {
      const users = store.state.scrapingResult.users || [];
      const email = [
        {
          email: "All",
        },
      ];
      const allUsers = email.concat(users);
      return allUsers;
    },
  },
  watch: {
    searchInput: function (val) {
      store.commit("scrapingResult/replaceValue", {
        key: "searchTerm",
        value: val,
      });

      if (this.acceptSearchInput) {
        this.acceptSearchInput = false;
        window.setTimeout(() => {
          if (this.initialLoad == false) {
            this.getResults();
          }
          this.acceptSearchInput = true;
        }, this.debounceSearchInputTimeout);
      }
    },
    selectedUser: function (val) {
      let user = val;
      console.log(val);
      if (val == "ALL" || val == undefined || val == null) {
        user = null;
      } else {
        user = val;
      }
      store.commit("scrapingResult/replaceValue", {
        key: "page",
        value: 1,
      });
      store.commit("scrapingResult/replaceValue", {
        key: "scraped_by",
        value: user,
      });
      if (this.initialLoad == false) {
        this.getResults();
      }
    },
  },
};
