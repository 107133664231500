var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-snackbar',{attrs:{"centered":"","color":"primary"},model:{value:(_vm.showClipboard),callback:function ($$v) {_vm.showClipboard=$$v},expression:"showClipboard"}},[_vm._v(" "+_vm._s(_vm.clipboardMsg)+" ")]),_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',{staticClass:"text-h1"},[_vm._v("Scraping Results")]),_c('v-spacer')],1),_c('v-row',{staticClass:"px-5 pt-5 ml-n8",attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"3"}},[_c('v-text-field',{attrs:{"label":"Filter"},model:{value:(_vm.searchInput),callback:function ($$v) {_vm.searchInput=$$v},expression:"searchInput"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-select',{attrs:{"clearable":"","items":_vm.users,"item-text":"email","item-value":"id","label":"Users"},model:{value:(_vm.selectedUser),callback:function ($$v) {_vm.selectedUser=$$v},expression:"selectedUser"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('date-picker',{ref:"datePickerStartDate",attrs:{"submitHandler":_vm.updateDateRangeStart,"initialStartDate":_vm.initialStartDate,"range":false,"clearInput":_vm.clearStart}}),_c('v-icon',{staticClass:"float-right mr-10 mt-n7",on:{"click":function($event){_vm.updateDateRangeStart([null]);
          _vm.clearStart = true;}}},[_vm._v("mdi-close")])],1),_c('v-col',{staticClass:"ml-n10",attrs:{"cols":"3"}},[_c('date-picker',{ref:"datePickerEndDate",attrs:{"submitHandler":_vm.updateDateRangeEnd,"initialStartDate":_vm.initialEndDate,"range":false,"clearInput":_vm.clearEnd}}),_c('v-icon',{staticClass:"float-right mr-10 mt-n7",on:{"click":function($event){_vm.updateDateRangeEnd([null]);
          _vm.clearEnd = true;}}},[_vm._v("mdi-close")])],1)],1),_c('v-row',{staticClass:"scrape"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.results,"items-per-page":-1,"no-data-text":'no data found',"disable-sort":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"body",fn:function(ref){
          var items = ref.items;
return _vm._l((items),function(item){return _c('tr',{key:item.id,on:{"mouseover":function($event){return _vm.selectItem(item)},"mouseleave":function($event){return _vm.unSelectItem()},"click":function($event){return _vm.scrapingDetails(item.id)}}},[_c('td',[_vm._v(" "+_vm._s(_vm.formatDate(item.created_at))+" ")]),_c('td',[_vm._v(" "+_vm._s(item.user_comment)+" ")]),_c('td',[_vm._v(" "+_vm._s(item.url)+" ")]),_c('td',[(item === _vm.selectedItem)?_c('div',[_c('v-tooltip',{attrs:{"color":"#121D30","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"white","text":""},on:{"click":function($event){$event.stopPropagation();return _vm.copyPermalink(item.id)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-link-variant")])],1)]}}],null,true),model:{value:(_vm.showCopyTooltip),callback:function ($$v) {_vm.showCopyTooltip=$$v},expression:"showCopyTooltip"}},[_c('span',[_vm._v("Copy Permalink")])])],1):_vm._e(),(item !== _vm.selectedItem)?_c('div',{staticStyle:{"height":"36px"}}):_vm._e()])])})}}])}),(_vm.results.length > 10)?_c('infinite-loading',{attrs:{"identifier":_vm.infiniteId},on:{"infinite":_vm.infiniteHandler}},[_c('div',{attrs:{"slot":"spinner"},slot:"spinner"},[_c('v-row',_vm._l((6),function(row,index){return _c('div',{key:index},[_c('v-skeleton-loader',{staticClass:"mx-auto",staticStyle:{"margin-top":"1em","margin-bottom":"1em"},attrs:{"max-width":"960px","width":"960px","type":"text","dark":""}}),_c('v-skeleton-loader',{staticClass:"mx-auto",staticStyle:{"margin-top":"1em","margin-bottom":"1em"},attrs:{"max-width":"960px","width":"960px","type":"divider","dark":""}})],1)}),0)],1),_c('div',{staticClass:"body-2 mt-2",attrs:{"slot":"no-more"},slot:"no-more"},[_vm._v(" ")]),_c('div',{staticClass:"body-2 mt-2",attrs:{"slot":"no-results"},slot:"no-results"},[_vm._v(" ")])]):_vm._e(),_c('v-fade-transition',[_c('v-btn',{directives:[{name:"scroll",rawName:"v-scroll",value:(_vm.onScroll),expression:"onScroll"},{name:"show",rawName:"v-show",value:(_vm.fab),expression:"fab"}],attrs:{"fab":"","dark":"","fixed":"","bottom":"","right":"","outlined":"","color":"white"},on:{"click":_vm.toTop}},[_c('v-icon',[_vm._v("mdi-arrow-up-bold")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }